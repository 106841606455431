import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {
  public progressBarText: number = 0;
  public sub: Subscription;
  constructor(private storage: Storage, private file :File) {
     }


  storeDataList(storageKey, data) {
    return this.storage.set(storageKey, JSON.stringify(data));
  }

  storeDataOneRecord(storageKey, data) {
    return this.storage.get(storageKey).then(storedData => {
      let storedObj = JSON.parse(storedData);

      if (storedObj) {
        storedObj.push(data);
      } else {
        storedObj = [data];
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(storageKey, JSON.stringify(storedObj));
    });
  }


  storeDataAddListToList(storageKey, data :any[]) {
    return this.storage.get(storageKey).then(storedData => {
      let storedObj = JSON.parse(storedData);

      if (storedObj) {
        data.forEach((dataRecord:any)=>{
          storedObj.push(dataRecord);
        })
      } else {
        storedObj = data;
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(storageKey, JSON.stringify(storedObj));
    });
  }

  removeRecordFromList(storageKey, id :string) {
    return this.storage.get(storageKey).then(storedData => {
      let storedObj = JSON.parse(storedData);

      if (storedObj) {
        let indx =storedObj.findIndex((element) => element.id > id);
        storedObj.splice(indx,1);
      } 
      // Save old & new local transactions back to Storage
      return this.storage.set(storageKey, JSON.stringify(storedObj));
    });
  }

  getStoredData(storageKey) {
    return this.storage.get(storageKey).then(storedData => {
      let storedObj = JSON.parse(storedData);
      return storedObj;
    }, (error) => {
      console.log(error)
    });
  }

  // deleteOnStorage() {
  //   return new Promise((resolve) => {
  //     this.storage.clear();
  //     let firebaseToken: any;
  //     if (localStorage.getItem(FIREBASE_TOKEN)) {
  //       firebaseToken = localStorage.getItem(FIREBASE_TOKEN);
  //     }
  //     localStorage.clear();
  //     localStorage.setItem(FIREBASE_TOKEN, firebaseToken)
  //     resolve(localStorage);
  //   });
  // }

  async writeFile(downloadedFile, wordname, extension, alphabet)  {
    if (downloadedFile == null) return;
    var filename = wordname+extension;
    await this.createFile(filename,extension,alphabet);
    return await this.writeToFile(filename,downloadedFile,extension,alphabet);
    //return this.file.dataDirectory + filename;
  }

  async createFile(filename, extension, alphabet) {
    let directory :string = "";
    // if(!this.file.checkDir(this.file.dataDirectory ,"words")){
    //   this.file.createDir(this.file.dataDirectory , "words", false).catch(err => {
    //     console.error(err);
    //   })
    // }
    // if(!this.file.checkDir(this.file.dataDirectory +"/words",alphabet)){
    //   this.file.createDir(this.file.dataDirectory +"/words", alphabet, false).catch(err => {
    //     console.error(err);
    //   })
    // }

    // if(!this.file.checkDir(this.file.dataDirectory +"/words/"+alphabet,"thumbnail")){
    //   this.file.createDir(this.file.dataDirectory +"/words/"+alphabet, "thumbnail", false).catch(err => {
    //     console.error(err);
    //   })
    // }
    await this.checkAndCreateDirectory(this.file.dataDirectory ,"words")

    await this.checkAndCreateDirectory(this.file.dataDirectory +"/words",alphabet)

    await this.checkAndCreateDirectory(this.file.dataDirectory +"/words/"+alphabet,"thumbnail")

    if(extension==".jpg"){
      directory = this.file.dataDirectory +"/words/"+alphabet+"/thumbnail"
    }else{
      directory = this.file.dataDirectory +"/words/"+alphabet
    }
    return this.file.createFile(directory, filename, false).catch(err => {
      console.error(err);
    })
  }

  async checkAndCreateDirectory(path: string, directory: string){
    this.file.checkDir(path,directory).then(async value=>{
      if(!value){
       await this.file.createDir(path, directory, false)
      }
    },async (error)=>{
      await this.file.createDir(path, directory, false)
    })
  }

  async writeToFile(filename, downloadedFile,extension, alphabet) {
    let directory : string = ""
    try {
      if(extension==".jpg"){
        directory = this.file.dataDirectory +"/words/"+alphabet+"/thumbnail"
      }else{
        directory = this.file.dataDirectory +"/words/"+alphabet
      }
      const createdFile = await this.file.writeFile(this.file.dataDirectory , filename, downloadedFile, { replace: true, append: false });
      console.log('File written successfully.');
      console.log(createdFile);
      return createdFile;
    } catch (err) {
      console.error(err);
    }
  }


  async getFile(path: string, filename: string){
    await this.file.readAsDataURL(path,filename).then(async value=>{
      return value;
    },async (error)=>{
      console.error(error);
    })
  }


}