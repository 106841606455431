import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx'
import { BehaviorSubject, Observable } from 'rxjs';
import {Platform, ToastController} from "@ionic/angular";
//import { ToastController, Platform } from 'ionic-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export enum ConnectionStatus {
  Online,
  Offline
}
@Injectable()
export class NetworkService {

networkType: any;
  // @ts-ignore
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

  constructor(private network: Network, private toastController: ToastController, private plt: Platform, private http :HttpClient) {
    this.plt.ready().then(async () => {
      this.initializeNetworkEvents();
      this.networkType = this.network.type;
      let status =  this.network.type == 'none' ? ConnectionStatus.Offline : ConnectionStatus.Online;
      if(status==ConnectionStatus.Online){
        await this.checkIfInternetAvailable();
      }
      this.status.next(status);
      console.log(this.network.type + " status: " +status);
    });
  }

  public initializeNetworkEvents() {

    this.network.onDisconnect().subscribe(() => {
      if (this.status.value === ConnectionStatus.Online) {
        console.log('WE ARE OFFLINE');
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });

    this.network.onConnect().subscribe(async () => {
      if (this.status.value === ConnectionStatus.Offline) {
        await this.checkIfInternetAvailable()
      }
    });
  }

  async checkIfInternetAvailable(){
    await this.callServer().then((res)=>{
      this.updateNetworkStatus(ConnectionStatus.Online);
    },(error)=>{
      this.updateNetworkStatus(ConnectionStatus.Offline);
    })
  }

  callServer() : Promise<any>{
    return this.http.get(
        environment.serverUrl
    ).toPromise()
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);

    let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';
    let toast = this.toastController.create({
      message: `You are now ${connection}`,
      duration: 3000,
      position: 'bottom'
    });
    // toast.then(toast => toast.present());
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    // console.log( this.status.getValue());
    return this.status.getValue();
  }
}
