import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { OfflineManagerService } from './pages/services/offline-manager-service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DataService } from './pages/services/data.service';
import { File, FileEntry } from '@ionic-native/file/ngx';
import {BackgroundFetch} from "@ionic-native/background-fetch/ngx";
import { NetworkService } from './pages/services/network-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private settings: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private route: Router,
    private data: DataService,
    private file: File,
    private offlineStorageService : OfflineManagerService,
    private backgroundFetch: BackgroundFetch,
    private network : NetworkService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.storage.create();
    this.platform.ready().then(async () => {
      this.statusBar.styleLightContent();
      await this.storage.create();
      this.initializeBackgroundFetch();
      this.network.initializeNetworkEvents();
      this.splashScreen.hide();
    });
  }

  initializeBackgroundFetch() {
    this.offlineStorageService.getStoredData(environment.settings).then((settings:any)=>{
      this.settings = settings;
      if(settings?.offlineMode){
        this.backgroundFetch.configure({
          stopOnTerminate: false, // Keep fetching even if the app is terminated
        }).then(()=>{
          console.log('Background scheduled:');
          this.onBackgroundFetch();
        },(error)=>{
          console.error('Background fetch configuration failed:', error);
        });

      }
    })
  }



  onBackgroundFetch() {
    // Perform data download or any other background task here
    const taskId = "downloadTask";
    this.backgroundFetch.start().then(()=>{
      console.log('Task started:', taskId);
      this.downloadWords();
    },(error)=>{
      console.error('Task start failed:', error);
    })
  }

  onBackgroundFetchError(error: any) {
    console.error('Background fetch error:', error);
  }

  gotoPage(pageName:any){
    this.route.navigate(["/"+pageName]);
  }

  downloadWords(){
    //get Offline words
    var storedWords;
    this.offlineStorageService.getStoredData(environment.storedWords).then((words:any)=>{
      storedWords= words;
      var stroredWordsIds = [];
      if(storedWords){
              //take ids of stored words
      }else{
        stroredWordsIds = [];
      }
      this.getNewWordAndSave(stroredWordsIds);
    })
  }

  getNewWordAndSave(storedWordsIds:string[]){

    this.data.getNewWords(storedWordsIds).subscribe((newWords:any)=>{
      //save word by word
      if(newWords){
        newWords.forEach(async (word:any)=>{
          // TODO download word thumbnail and save
          this.saveWord(word);
        })
      }
    })
  }

  async saveWord( word:any){
    const wordToSave : any={}
    wordToSave.id = word.id;
    wordToSave.name = word.name;
    wordToSave.definition={}
    wordToSave.definition.id = word.definition.id;
    wordToSave.alphabetName=word.alphabetName;
    let base64Image = word.thumbnail;
    let base64Video = word.definition.video;
    let thumbnail = new Blob([base64Image], { type: 'image/jpeg' });
    let video = new Blob([base64Video], { type: 'video/mp4' });
    await this.offlineStorageService.writeFile(thumbnail,word.name,".jpg", word.alphabetName).then(result=>{
      let fileEntry : FileEntry = result;
      wordToSave.thumbnail = fileEntry.toURL();
    });
    await this.offlineStorageService.writeFile(video,word.name,".mp4", word.alphabetName).then(result=>{
      let fileEntry : FileEntry = result;
      wordToSave.definition.video = fileEntry.toURL();
    });
    this.offlineStorageService.storeDataOneRecord(environment.storedWords,wordToSave);
  }

}
