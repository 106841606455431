import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OfflineManagerService } from './offline-manager-service';
import { catchError, map } from 'rxjs/operators';


export interface Word {
  name: string;
  alphabet: string;
  thumbnail: string;
  id: number;
}

export interface WordModel {
  name: string;
  alphabet: string;
  files: File[];
  definitions: Definition[];
  id: string;
}

export interface File{
  id: String;
  fileName:string;
  url: string;
  mimeType: string;
}

export interface Definition {
  id: string;
  files: File[];
}


@Injectable({
  providedIn: 'root'
})
export class DataService {


  public words: Word [] =[{
    name: "Aback",
    alphabet: "a",
    thumbnail: "assets/videos/a/Aback.mp4",
    id: 0
  },
  {
    name: "Abadon",
    alphabet: "a",
    thumbnail: "assets/videos/a/Abadon.mp4",
    id: 1
  },
  {
    name: "Able",
    alphabet: "a",
    thumbnail: "assets/videos/a/Able.mp4",
    id: 2
  },
  {
    name: "Abolish",
    alphabet: "a",
    thumbnail: "assets/videos/a/Abolish.pm4",
    id: 3
  }, 
  {
    name: "Abortion",
    thumbnail: "assets/videos/a/Abortion.mp4",
    alphabet: "a",
    id: 4
  },
  {
    name: "Abuse",
    alphabet: "a",
    thumbnail: "assets/videos/a/Abuse.mp4",
    id: 5
  },
  {
    name: "Accept",
    thumbnail: "assets/videos/a/Accept.mp4",
    alphabet: "a",
    id: 6
  },
  {
    name: "Achieve",
    thumbnail: "assets/videos/a/Achieve.mp4",
    alphabet: "a",
    id: 7
  },
  {
    name: "Admire",
    thumbnail: "assets/videos/a/Admire.mp4",
    alphabet: "a",
    id: 8
  },
  {
    name: "Advice",
    alphabet: "a",
    thumbnail: "assets/videos/a/Advice.mp4",
    id: 9
  },
  {
    name: "Aesthetics",
    thumbnail: "assets/videos/a/Aesthetics.mp4",
    alphabet: "a",
    id: 10
  },
  {
    name: "After",
    alphabet: "a",
    thumbnail: "assets/videos/a/After.mp4",
    id: 11
  },
  {
    name: "Afternoon",
    thumbnail: "assets/videos/a/Afternoon.mp4",
    alphabet: "a",
    id: 12
  },
  {
    name: "Again",
    thumbnail: "assets/videos/a/Again.mp4",
    alphabet: "a",
    id: 13
  },
  {
    name: "Age",
    alphabet: "a",
    thumbnail: "assets/videos/a/Age.mp4",
    id: 14
  },
  {
    name: "Agitation",
    alphabet: "a",
    thumbnail: "assets/videos/a/Agitation.mp4",
    id: 15
  },
  {
    name: "Agree",
    thumbnail: "assets/videos/a/Agree.mp4",
    alphabet: "a",
    id: 16
  },
  {
    name: "Agriculture",
    thumbnail: "assets/videos/a/Agriculture.mp4",
    alphabet: "a",
    id: 17
  },
  {
    name: "Alike",
    thumbnail: "assets/videos/a/Alike.mp4",
    alphabet: "a",
    id: 18
  },
  {
    name: "Allow",
    thumbnail: "assets/videos/a/Allow.mp4",
    alphabet: "a",
    id: 19
  },
  {
    name: "Alone",
    thumbnail: "assets/videos/a/Alone.mp4",
    alphabet: "a",
    id: 20
  },
  {
    name: "Angel",
    thumbnail: "assets/videos/a/Angel.mp4",
    alphabet: "a",
    id: 20
  },
  {
    name: "Anger",
    thumbnail: "assets/a/Angel.mp4",
    alphabet: "a",
    id: 20
  },
  {
    name: "Angel",
    thumbnail: "assets/videos/a/Anticipate.mp4",
    alphabet: "a",
    id: 20
  },
  {
    name: "Baby",
    thumbnail: "assets/videos/b/Baby.mp4",
    alphabet: "b",
    id: 20
  },{
    name: "Bad",
    thumbnail: "assets/videos/b/Bad.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Bag",
    thumbnail: "assets/videos/b/Bag.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Ball",
    thumbnail: "assets/videos/b/Ball.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Banana",
    thumbnail: "assets/videos/b/Baby.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Bath",
    thumbnail: "assets/videos/b/Bath.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Beautiful",
    thumbnail: "assets/videos/b/Beautiful.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Bed",
    thumbnail: "assets/videos/b/Bed.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Begin",
    thumbnail: "assets/videos/b/Begin.mp4",
    alphabet: "b",
    id: 20
  },  {
    name: "Behaviour",
    thumbnail: "assets/videos/b/Behaviour.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Belief",
    thumbnail: "assets/videos/b/Belief.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Benefit",
    thumbnail: "assets/videos/b/Benefit.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Bible",
    thumbnail: "assets/videos/b/Bible.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Bicycle",
    thumbnail: "assets/videos/b/Bicycle.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Big",
    thumbnail: "assets/videos/b/Big.mp4",
    alphabet: "b",
    id: 20
  },
  {
    name: "Cabbage",
    thumbnail: "assets/videos/c/Cabbage.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Calculate",
    thumbnail: "assets/videos/c/Calculate.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Calender",
    thumbnail: "assets/videos/c/Calender.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Call",
    thumbnail: "assets/videos/c/Call.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Camera",
    thumbnail: "assets/videos/c/Camera.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cancel",
    thumbnail: "assets/videos/c/Cancel.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Car",
    thumbnail: "assets/videos/c/Car.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cat",
    thumbnail: "assets/videos/c/Cat.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Chair",
    thumbnail: "assets/videos/c/Chair.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cheap",
    thumbnail: "assets/videos/c/Cheap.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Chew",
    thumbnail: "assets/videos/c/Cheap.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Chicken",
    thumbnail: "assets/videos/c/Chicken.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Christmas",
    thumbnail: "assets/videos/c/Christmas.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cinema",
    thumbnail: "assets/videos/c/Cinema.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Clip",
    thumbnail: "assets/videos/c/Clip.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Close",
    thumbnail: "assets/videos/c/Close.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cloud",
    thumbnail: "assets/videos/c/Cloud.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Come",
    thumbnail: "assets/videos/c/Come.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Complete",
    thumbnail: "assets/videos/c/Complete.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Control",
    thumbnail: "assets/videos/c/Control.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Cook",
    thumbnail: "assets/videos/c/Cook.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Correct",
    thumbnail: "assets/videos/c/Correct.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Crime",
    thumbnail: "assets/videos/c/Crime.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Curtain",
    thumbnail: "assets/videos/c/Curtain.mp4",
    alphabet: "c",
    id: 20
  },
  {
    name: "Dance",
    thumbnail: "assets/videos/d/Dance.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dangerous",
    thumbnail: "assets/videos/d/Dangerous.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dark",
    thumbnail: "assets/videos/d/Dark.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Daughter",
    thumbnail: "assets/videos/d/Daughter.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Day",
    thumbnail: "assets/videos/d/Day.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dead",
    thumbnail: "assets/videos/d/Dead.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Deaf",
    thumbnail: "assets/videos/d/Deaf.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Debate",
    thumbnail: "assets/videos/d/Debat.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Decorate",
    thumbnail: "assets/videos/d/Decorate.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Define",
    thumbnail: "assets/videos/d/Define.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Degree",
    thumbnail: "assets/videos/d/Degree.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Deodorant",
    thumbnail: "assets/videos/d/Deodorant.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Depression",
    thumbnail: "assets/videos/d/Depression.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Diamiond",
    thumbnail: "assets/videos/d/Diamond.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Diet",
    thumbnail: "assets/videos/d/Diet.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Disability",
    thumbnail: "assets/videos/d/Disability.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Disease",
    thumbnail: "assets/videos/d/Disease.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Divide",
    thumbnail: "assets/videos/d/Divide.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Doctor",
    thumbnail: "assets/videos/d/Doctor.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Document",
    thumbnail: "assets/videos/d/Document.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dog",
    thumbnail: "assets/videos/d/Dog.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dress",
    thumbnail: "assets/videos/d/Dress.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Drug",
    thumbnail: "assets/videos/d/Drug.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dress",
    thumbnail: "assets/videos/d/Dress.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Dust",
    thumbnail: "assets/videos/d/Dust.mp4",
    alphabet: "d",
    id: 20
  },
  {
    name: "Gadget",
    thumbnail: "assets/videos/g/Gadget.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gender",
    thumbnail: "assets/videos/g/Gender.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "General",
    thumbnail: "assets/videos/g/General.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Genius",
    thumbnail: "assets/videos/g/Genius.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gentle",
    thumbnail: "assets/videos/g/Gentle.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Girl",
    thumbnail: "assets/videos/g/Girl.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Glad",
    thumbnail: "assets/videos/g/Glad.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Global",
    thumbnail: "assets/videos/g/Global.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Glossy",
    thumbnail: "assets/videos/g/Glossy.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Glue",
    thumbnail: "assets/videos/g/Glue.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gorvernment",
    thumbnail: "assets/videos/g/Gorvernment.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Grasp",
    thumbnail: "assets/videos/g/Grasp.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Greed",
    thumbnail: "assets/videos/g/Greed.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Grief",
    thumbnail: "assets/videos/g/Grief.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Grin",
    thumbnail: "assets/videos/g/Grin.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Ground",
    thumbnail: "assets/videos/g/Ground.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Grow",
    thumbnail: "assets/videos/g/Grow.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Guarantee",
    thumbnail: "assets/videos/g/Guarantee.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Guardian",
    thumbnail: "assets/videos/g/Guardian.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Guilt",
    thumbnail: "assets/videos/g/Guilt.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gulp",
    thumbnail: "assets/videos/g/Gulp.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gun",
    thumbnail: "assets/videos/g/Gun.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gym",
    thumbnail: "assets/videos/g/Gym.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Gynaecologist",
    thumbnail: "assets/videos/g/Gynaecologist.mp4",
    alphabet: "g",
    id: 20
  },
  {
    name: "Facilitate",
    thumbnail: "assets/videos/f/Facilitate.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fact",
    thumbnail: "assets/videos/f/Fact.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fair",
    thumbnail: "assets/videos/f/Fair.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Family",
    thumbnail: "assets/videos/f/Family.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Far",
    thumbnail: "assets/videos/f/Far.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fascinate",
    thumbnail: "assets/videos/f/Fascinate.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fast",
    thumbnail: "assets/videos/f/Fast.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Father",
    thumbnail: "assets/videos/f/Father.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Favour",
    thumbnail: "assets/videos/f/Favour.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Feed",
    thumbnail: "assets/videos/f/Feed.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Feel",
    thumbnail: "assets/videos/f/Feel.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Female",
    thumbnail: "assets/videos/f/Female.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fight",
    thumbnail: "assets/videos/f/Fight.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Filthy",
    thumbnail: "assets/videos/f/Filthy.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Finish",
    thumbnail: "assets/videos/f/Finish.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fitness",
    thumbnail: "assets/videos/f/Fitness.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Flat",
    thumbnail: "assets/videos/f/Flat.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Flavour",
    thumbnail: "assets/videos/f/Flavour.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Flood",
    thumbnail: "assets/videos/f/Flood.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fluid",
    thumbnail: "assets/videos/f/Fluid.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Follow",
    thumbnail: "assets/videos/f/Follow.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Foreign",
    thumbnail: "assets/videos/f/Foreign.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fragile",
    thumbnail: "assets/videos/f/Fragile.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Fright",
    thumbnail: "assets/videos/f/Fright.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Funny",
    thumbnail: "assets/videos/f/Funny.mp4",
    alphabet: "f",
    id: 20
  },
  {
    name: "Hairy",
    thumbnail: "assets/videos/h/Hairy.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Handy",
    thumbnail: "assets/videos/h/Handy.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Happy",
    thumbnail: "assets/videos/h/Happy.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hard",
    thumbnail: "assets/videos/h/Hard.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hat",
    thumbnail: "assets/videos/h/Hat.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Health",
    thumbnail: "assets/videos/h/Health.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Heart",
    thumbnail: "assets/videos/h/Heart.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Heavy",
    thumbnail: "assets/videos/h/Heavy.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Help",
    thumbnail: "assets/videos/h/Help.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hide",
    thumbnail: "assets/videos/h/Hide.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hire",
    thumbnail: "assets/videos/h/Hire.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "History",
    thumbnail: "assets/videos/h/History.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hold",
    thumbnail: "assets/videos/h/Hold.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Homework",
    thumbnail: "assets/videos/h/Homework.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Honest",
    thumbnail: "assets/videos/h/Honest.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hope",
    thumbnail: "assets/videos/h/Hope.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hospital",
    thumbnail: "assets/videos/h/Hospital.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "House",
    thumbnail: "assets/videos/h/House.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Human",
    thumbnail: "assets/videos/h/Human.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hungry",
    thumbnail: "assets/videos/h/Hungry.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hunt",
    thumbnail: "assets/videos/h/Hunt.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hurt",
    thumbnail: "assets/videos/h/Hurt.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hut",
    thumbnail: "assets/videos/h/Hut.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hygiene",
    thumbnail: "assets/videos/h/Hygiene.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Hymn",
    thumbnail: "assets/videos/h/Hymn.mp4",
    alphabet: "h",
    id: 20
  },
  {
    name: "Early",
    thumbnail: "assets/videos/e/Early.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Easy",
    thumbnail: "assets/videos/e/Easy.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Eat",
    thumbnail: "assets/videos/e/Eat.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Edit",
    thumbnail: "assets/videos/e/Edit.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Educate",
    thumbnail: "assets/videos/e/Educate.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Effect",
    thumbnail: "assets/videos/e/Effect.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Effort",
    thumbnail: "assets/videos/e/Effort.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Emergency",
    thumbnail: "assets/videos/e/Emergency.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Empower",
    thumbnail: "assets/videos/e/Empower.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Empty",
    thumbnail: "assets/videos/e/Empty.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Encourage",
    thumbnail: "assets/videos/e/Encourage.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "End",
    thumbnail: "assets/videos/e/End.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Energy",
    thumbnail: "assets/videos/e/Energy.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Enjoy",
    thumbnail: "assets/videos/e/Enjoy.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Enquire",
    thumbnail: "assets/videos/e/Enquire.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Environment",
    thumbnail: "assets/videos/e/Environment.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Equipment",
    thumbnail: "assets/videos/e/Equipment.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Escape",
    thumbnail: "assets/videos/e/Escape.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Evening",
    thumbnail: "assets/videos/e/Evening.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Evict",
    thumbnail: "assets/videos/e/Evict.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Exaggerate",
    thumbnail: "assets/videos/e/Exaggerate.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Excellent",
    thumbnail: "assets/videos/e/Excellent.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Excited",
    thumbnail: "assets/videos/e/Excited.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Exercise",
    thumbnail: "assets/videos/e/Exercise.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Exhausted",
    thumbnail: "assets/videos/e/Exhausted.mp4",
    alphabet: "e",
    id: 20
  },
  {
    name: "Ice",
    thumbnail: "assets/videos/i/Ice.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Ideal",
    thumbnail: "assets/videos/i/Ideal.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Identity",
    thumbnail: "assets/videos/i/Identity.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Ignorant",
    thumbnail: "assets/videos/i/Ignorant.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Illegal",
    thumbnail: "assets/videos/i/Illegal.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Improvement",
    thumbnail: "assets/videos/i/Improvement.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Indepth",
    thumbnail: "assets/videos/i/Indepth.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Indoors",
    thumbnail: "assets/videos/i/Indoors.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Infant",
    thumbnail: "assets/videos/i/Infant.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Infection",
    thumbnail: "assets/videos/i/Infection.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Inferior",
    thumbnail: "assets/videos/i/Inferior.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Inflate",
    thumbnail: "assets/videos/i/Inflate.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Initiate",
    thumbnail: "assets/videos/i/Initiate.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Innocent",
    thumbnail: "assets/videos/i/Innocent.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Innovate",
    thumbnail: "assets/videos/i/Innovate.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Insert",
    thumbnail: "assets/videos/i/Insert.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Inspect",
    thumbnail: "assets/videos/i/Inspect.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Instruction",
    thumbnail: "assets/videos/i/Instruction.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Insult",
    thumbnail: "assets/videos/i/Insult.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Intelligent",
    thumbnail: "assets/videos/i/Intelligent.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Intoxicated",
    thumbnail: "assets/videos/i/Intoxicated.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Invasion",
    thumbnail: "assets/videos/i/Invasion.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Invisible",
    thumbnail: "assets/videos/i/Invisible.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Irritate",
    thumbnail: "assets/videos/i/Irritate.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Isolate",
    thumbnail: "assets/videos/i/Isolate.mp4",
    alphabet: "i",
    id: 20
  },
  {
    name: "Jab",
    thumbnail: "assets/videos/j/Jab.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jail",
    thumbnail: "assets/videos/j/Jail.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jar",
    thumbnail: "assets/videos/j/Jar.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jargon",
    thumbnail: "assets/videos/j/Jargon.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jeans",
    thumbnail: "assets/videos/j/Jeans.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jewellery",
    thumbnail: "assets/videos/j/Jewellery.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Job",
    thumbnail: "assets/videos/j/Job.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jog",
    thumbnail: "assets/videos/j/Jog.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Joggle",
    thumbnail: "assets/videos/j/Joggle.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Join",
    thumbnail: "assets/videos/j/Join.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Joint",
    thumbnail: "assets/videos/j/Joint.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Joke",
    thumbnail: "assets/videos/j/Joke.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jolly",
    thumbnail: "assets/videos/j/Jolly.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Journey",
    thumbnail: "assets/videos/j/Journey.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Joyful",
    thumbnail: "assets/videos/j/Joyful.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Judge",
    thumbnail: "assets/videos/j/Judge.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Judicius",
    thumbnail: "assets/videos/j/Judicius.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jug",
    thumbnail: "assets/videos/j/Jug.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Juice",
    thumbnail: "assets/videos/j/Juice.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jump",
    thumbnail: "assets/videos/j/Jump.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Jungle",
    thumbnail: "assets/videos/j/Jungle.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Junior",
    thumbnail: "assets/videos/j/Junior.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Justice",
    thumbnail: "assets/videos/j/Justice.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Juvenile",
    thumbnail: "assets/videos/j/Juvenile.mp4",
    alphabet: "j",
    id: 20
  },
  {
    name: "Juvenile",
    thumbnail: "assets/videos/j/Juvenile.mp4",
    alphabet: "j",
    id: 20
  },
];

public wordDefinitions: any [] =[
  {
  text: "A regretful acknowledgement of an offence or failure.",
  signLanguage: "assets/videos/apology.mp4",
  audio: "assets/audio/apology.mp3",
  id: 0,
  word: "apology"
},
{
  text: "Abuse",
  signLanguage: "assets/videos/Abuse.mp4",
  audio: "assets/videos/thumbnails/Abuse.jpg",
  id: 0,
  word: "abuse"
},
{
  text: "Apology",
  signLanguage: "a",
  audio: "assets/videos/thumbnails/apology.jpg",
  id: 0,
  word: "aplology"
},
{
  text: "Apology",
  signLanguage: "a",
  audio: "assets/videos/thumbnails/apology.jpg",
  id: 0,
  word: "aplology"
},
{
  text: "Apology",
  signLanguage: "a",
  audio: "assets/videos/thumbnails/apology.jpg",
  id: 0,
  word: "aplology"
},
{
  text: "Apology",
  signLanguage: "a",
  audio: "assets/videos/thumbnails/apology.jpg",
  id: 0,
  word: "aplology"
}
];

  public alphabets: any[] = [];

  constructor(private http: HttpClient, private offlineManagerService : OfflineManagerService ) { }

  public async getAlphabets(): Promise<any[]> {
    this.alphabets =[]
    await this.offlineManagerService.getStoredData(environment.storedAlphabets).then (( alphabets:any)=>{
      this.alphabets = alphabets;
    })
    return this.alphabets;
  }

  public getWordsByAlphabet(id:string): any[] {
    let filteredWords =[];
    this.offlineManagerService.getStoredData(environment.storedWords).then (( words:any)=>{
      this.words = words;
      filteredWords = this.words.filter(word => word.alphabet.toLowerCase() == id.toLowerCase());
      return filteredWords;
    })
    return filteredWords;
  }

  public getWordDefinition(id:string): any[] {
    let filteredWords =[];
    filteredWords = this.wordDefinitions.filter(definition => definition.word.toLowerCase() === id.toLowerCase());
    return filteredWords;
  }

  public getWordById(id:string): any {
    let word ={};
    this.offlineManagerService.getStoredData(environment.storedWords).then (( words:any)=>{
      this.words = words;
      return this.words.find(word => word.name.toLowerCase() === id.toLowerCase());
    })
    return word;
  }

  public searchWord(searchText:any) : any [] {
    let filteredWords =[];
     this.offlineManagerService.getStoredData(environment.storedWords).then (( words:any)=>{
      this.words = words;
      filteredWords = this.words.filter(word => word.name.toLowerCase().indexOf(searchText) > -1);
      return filteredWords;
    });
    return filteredWords;
  }

  public searchWordsOnline(searchText:any) : Observable<any>{
    return this.http.post(
      environment.serverUrl + '/api/words/v1/search',
      searchText,
    )
  }

  public getAllAlphabetsOnline() {
    return this.http.get(
        environment.serverUrl + '/api/alphabets/v1/'
    ).pipe(map(res=>res),
    catchError(error => throwError(error)))
  }


  getWordByIdOnline(wordId:any) : Observable<any>{
    return this.http.get(
        environment.serverUrl + '/api/words/v1/'+wordId
    )
  }

  getWordByAlphabetIdOnline(alphabetId:any, limit:any) :Observable<any>{
    return this.http.get(
        environment.serverUrl + '/api/words/v1/alphabet/'+alphabetId +"/limit/"+limit
    ).pipe(catchError(error => throwError(error)));
  }

  // downloadWords(alphabetId:any) : Observable<any>{
  //   return this.http.get(
  //       environment.serverUrl + 'api/words/v1/alphabet/'+alphabetId
  //   )
  // }

  downloadFile(googleId:any) : Promise<any>{
    return this.http.post(
        environment.serverUrl + '/api/words/v1/download/file/'+googleId,'',{ responseType: 'text' }
    ).toPromise()
  }

  downloadFileAndCompress(googleId:any) : Promise<any>{
    return this.http.post(
        environment.serverUrl + '/api/words/v1/download/file/compressed/'+googleId,'',{ responseType: 'text' }
    ).toPromise()
  }

  getNewWords(wordIds :string[]){
    return this.http.post(
      environment.serverUrl + '/api/words/v1/new',
      wordIds
  )
  
}

addNewWordView(wordId :string){
  return this.http.get(
    environment.serverUrl + '/api/words/v1/view/add/'+wordId
)

}

getMostViewedWords(wordId :number){
  return this.http.get(
    environment.serverUrl + '/api/words/v1/view/most/'+wordId
)
}

getWordViewsByWordId(wordId :string){
  return this.http.get(
    environment.serverUrl + '/api/words/v1/view/'+wordId
)
}

searchWordOnline(searchText :string){
  return this.http.post(
    environment.serverUrl + '/api/words/v1/search',
    {name:searchText}
)
}

getWordOfTheDay() : Promise<any>{
  let date = new Date();
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      +date.getDate();
  return this.http.get(
      environment.serverUrl + '/api/words/v1/wordofday/'+date_String
  ).toPromise()
}



  
}
