import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
 
 
@Injectable()
export class InterceptorProvider implements HttpInterceptor {
 
    constructor(private storage: Storage,private router: Router) { }
 
    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next));
    }

    private async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
          request = request.clone({
            setHeaders: {
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods': 'POST, GET, PUT',
            }
          });
    
        return next.handle(request).pipe(catchError(this.handleError.bind(this))).toPromise();
      }
    
      private handleError(error: HttpErrorResponse) {
        let appError = {
          message: error?.error.error || error.statusText,
          serverMessage: '',
          data: '',
          statusCode: error.status
        };
        if (error.error.message) {
          appError.serverMessage = error.error.message;
        }
    
        if(error.error.data){
          appError.data = error.error.data
        }
        switch (error.status) {
          case 0:
            appError.message = 'Unable to reach server';
            break;
          case 400:
            console.log('BAD REQUEST');
            break;
          case 401:
            console.log('401');
            this.router.navigate(['auth']);
            break;
          case 403:
            console.log('403');
            break;
          case 404:
            console.log('NOT FOUND REQUEST');
            break;
          case 500:
          case 502:
          case 503:
          default:
            console.log('DEFAULT');
        }
    
        return throwError(appError);
      }
}